<template>
    <div class="w-full flex" style="height: 100px;" :style="'background: #' + data.Background">
        <div class="self-center container flex justify-between">
            <h1 class="text-3xl font-bold">{{ data.Title }}</h1>
            <div class="flex">
                <div class="flex my-auto" v-for="(item, n) in data.Data" :key="n">
                    <span class="my-auto text-lg" @click="item.Url ? $router.push(item.Url) : ''" :class="item.Url ? 'font-semibold hover:text-pink-500' : 'text-gray-600'">{{ item.Title }}</span>
                    <div class="my-auto mx-2" v-if="n !== (data.Data.length - 1)">
                        <b-icon
                            icon="chevron-right"
                            size="is-small">
                        </b-icon>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        data: {
            type: Object
        }
    }
}
</script>
